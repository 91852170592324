<template>
  <a-row :gutter="24" class="table-row">
    <a-col :md="24">
      <a-card :bordered="false">
        <!-- 查询区域 -->
        <div class="table-page-search-top">
          <div class="table-page-search-wrapper table-page-search-header">
            <a-form layout="inline" @keyup.enter.native="searchQuery">
              <a-row :gutter="24">
                <a-col :md="6" :sm="24">
                  <a-form-item
                    label="登錄名稱"
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                  >
                    <a-input
                      placeholder="輸入登錄名稱模糊查詢"
                      v-model="queryParam.loginName"
                    ></a-input>
                  </a-form-item>
                </a-col>
                <!-- <a-col :md="6" :sm="24">
                  <a-form-item
                    label="用户姓名"
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                  >
                    <a-input
                      placeholder="输入用户姓名模糊查询"
                      v-model="queryParam.userName"
                    ></a-input>
                  </a-form-item>
                </a-col> -->
              </a-row>
            </a-form>
          </div>
          <div class="table-page-search">
            <div class="button-onditions">
              <a-button icon="search" type="primary" @click="searchQuery"
                >查詢</a-button
              >
              <!-- <a-button icon="sync" @click="searchReset">重置</a-button> -->
            </div>
          </div>
        </div>
        <!-- 操作按钮区域 -->
        <div class="table-operator" style="margin-top: 5px">
          <!-- @click="handleCommonAdd" -->
          <!-- v-if="btnEnableList.indexOf(1) > -1" -->
          <a-button
            @click="handleAdd"
            type="primary"
            icon="plus"
            style="margin: 0px 8px 8px 0px"
            id="add"
            >新增用戶</a-button
          >
        </div>
        <!-- table区域-begin -->
        <div>
          <a-table
            ref="table"
            bordered
            size="middle"
            rowKey="id"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="ipagination"
            :loading="loading"
            @change="handleTableChange"
            class="table_info"
            :scroll="{ x: scrollX, y: scrollY }"
          >
            <span slot="action" slot-scope="text, record">
              <a-popconfirm
                title="確定重置密碼爲123456嗎?"
                @confirm="() => handleReset(record.id)"
              >
                <a-tag color="#2db7f5">重置密碼</a-tag>
              </a-popconfirm>
              <a-tag @click="handleEdit(record)" color="#87d068">編輯</a-tag>
              <a-popconfirm
                title="確定刪除嗎?"
                @confirm="() => handleDelete(record.id)"
              >
                <a-tag color="#f50">删除</a-tag>
              </a-popconfirm>
            </span>
            <!-- 状态渲染模板 -->
            <template slot="customRenderFlag" slot-scope="status">
              <a-tag v-if="status === 0" color="green">啓用</a-tag>
              <a-tag v-if="status === 2" color="orange">禁用</a-tag>
            </template>
          </a-table>
        </div>
        <!-- table区域-end -->
        <user-modal ref="modalForm" @ok="modalFormOk"></user-modal>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import UserModal from "./modules/UserModal";
import { postAction } from "@/api/manage";
import { AdapterTableMixin } from "@/mixins/AdapterTableMixin";
import { getCurrentSystemConfig, findUserRole } from "@/api/api";
import { JeecgListMixin } from "@/mixins/JeecgListMixin";
export default {
  name: "UserList",
  mixins: [JeecgListMixin, AdapterTableMixin],
  components: {
    UserModal,
  },
  data() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 18,
        offset: 1,
      },
      queryParam: {},
      depotFlag: "0",
      customerFlag: "0",
      roleList: [],
      tenantList: [],
      columns: [
        {
          title: "#",
          dataIndex: "",
          key: "rowIndex",
          width: 40,
          align: "center",
          ellipsis: true,
          customRender: function (t, r, index) {
            return parseInt(index) + 1;
          },
        },
        {
          title: "登錄名稱",
          dataIndex: "loginName",
          width: 160,
          align: "center",
          ellipsis: true,
        },
        {
          title: "用戶姓名",
          dataIndex: "username",
          width: 160,
          align: "center",
          ellipsis: true,
        },
        {
          title: "狀態",
          dataIndex: "status",
          width: 70,
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "customRenderFlag" },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          ellipsis: true,
          width: 260,
        },
      ],
      url: {
        list: "/user/listPage",
        delete: "/user/delete",
        resetPwd: "/user/resetPwd",
        batchSetStatusUrl: "/user/batchSetStatus",
      },
    };
  },
  created() {
    // this.getSystemConfig();
    // this.loadRoleData();
  },
  methods: {
    getSystemConfig() {
      // getCurrentSystemConfig().then((res) => {
      //   if (res.code === 200 && res.data) {
      //     this.depotFlag = res.data.depotFlag;
      //     this.customerFlag = res.data.customerFlag;
      //   }
      // });
    },
    handleAdd: function () {
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "新增";
      this.$refs.modalForm.disableSubmit = false;
    },
    searchQuery() {
      this.loadData(1);
      this.getSystemConfig();
    },
    searchReset() {
      this.queryParam = {};
      this.loadData(1);
      this.getSystemConfig();
    },
    handleEdit: function (record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑";
      this.$refs.modalForm.disableSubmit = false;
      if (this.btnEnableList.indexOf(1) === -1) {
        this.$refs.modalForm.isReadOnly = true;
      }
    },
    handleReset(id) {
      let that = this;
      postAction(that.url.resetPwd, { id: id }).then((res) => {
        if (res.code === 200) {
          that.$message.info("重置密码成功！");
          that.loadData();
        } else {
          that.$message.warning(res.data.message);
        }
      });
    },
    btnSetDepot(record) {},
    btnSetCustomer(record) {},
    loadRoleData() {
      let that = this;
      let params = {
        UBType: "UserRole",
        UBKeyId: "",
      };
      findUserRole(params).then((res) => {
        if (res) {
          that.roleList = res.map((item) => {
            item.value = item.id;
            item.label = item.text;
            return item;
          });
        }
      });
    },
  },
};
</script>
<style scoped>
@import "~@assets/less/common.less";
@import "~@assets/less/TaskProcess.less";
@import "~@assets/less/button.less";
.table_info ::v-deep .ant-table-tbody > tr > td {
  padding: 5px 8px !important;
  overflow-wrap: break-word;
  font-weight: 500;
}
</style>
