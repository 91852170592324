<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="關閉"
    wrapClassName="ant-modal-cust-warp"
    style="top: 5%; height: 100%; overflow-y: hidden"
  >
    <template slot="footer">
      <a-button key="back" v-if="isReadOnly" @click="handleCancel">
        關閉
      </a-button>
    </template>
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="登錄名稱"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="請輸入登錄名稱"
            v-decorator.trim="['loginName', validatorRules.loginName]"
            suffix="初始密碼：123456"
          />
        </a-form-item>
        <a-form-item
          label="用戶姓名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入用戶姓名"
            v-decorator.trim="['username', validatorRules.username]"
          />
        </a-form-item>
        <!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="单位">
          <a-tree-select
            style="width:100%"
            :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }"
            allow-clear
            @select="onSelect"
            :treeDefaultExpandAll="treeDefaultExpandAll"
            :treeData="orgaTree"
            v-decorator="['orgaId', validatorRules.orgaId]"
            placeholder="请选择单位"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="角色">
          <a-checkbox-group
            placeholder="请选择角色"
            :rows="2"
            v-decorator.trim="['roleIds']"
            :options="roleList"
            @change="changeRole"
          />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="职位">
          <a-input placeholder="请输入职位" v-decorator.trim="['position']" />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="电话号码"
        >
          <a-input
            placeholder="请输入电话号码"
            v-decorator.trim="['phonenum', validatorRules.phonenum]"
            @blur="handleBlurPhonenum"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="电子邮箱"
        >
          <a-input
            placeholder="请输入电子邮箱"
            v-decorator.trim="['email', validatorRules.email]"
          />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="描述">
          <a-textarea
            :rows="1"
            placeholder="请输入描述"
            v-decorator="['description']"
          />
        </a-form-item> -->
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import { pick } from "lodash";
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { getAction } from "@/api/manage";
import {
  addUser,
  editUser,
  queryOrganizationTreeList,
  findUserRole,
  selectCurrentOrgList,
  selectQqualOrgList,
  queryOrganizationById,
} from "@/api/api";
import { disabledAuthFilter } from "@/utils/authFilter";
import { duplicateCheck } from "@/api/api";
export default {
  name: "UserModal",
  components: {},
  data() {
    return {
      title: "操作",
      visible: false,
      modalWidth: 800,
      drawerWidth: 700,
      orgaTree: [],
      roleList: [],
      rolesList: [],
      tenantList: [],
      userId: "", //保存用户id
      personType: null,
      isReadOnly: false,
      disableSubmit: false,
      dateFormat: "YYYY-MM-DD",
      treeDefaultExpandAll: true,
      validatorRules: {
        loginName: {
          rules: [
            {
              required: true,
              message: "請輸入登錄名稱!",
            },
          ],
        },
        username: {
          rules: [
            {
              required: true,
              message: "请输入用戶姓名!",
            },
          ],
        },
        roleIds: {
          // rules: [{
          //   required: true, message: '请选择角色!'
          // }]
        },
        // tenantId:{
        //   rules: [{
        //     required: true, message: '请选择角色!'
        //   }]
        // },
        orgaId: {
          rules: [
            {
              required: true,
              message: "请选择单位!",
            },
          ],
        },
        phonenum: {
          rules: [
            { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的联系电话！" },
            { required: true, message: "请输入联系电话！" },
          ],
        },
        email: {
          rules: [
            {
              pattern: /^\w+@[a-z0-9]+.[a-z]{2,4}$/,
              message: "请输入正确的电子邮箱！",
            },
          ],
        },
      },
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      uploadLoading: false,
      confirmLoading: false,
      headers: {},
      form: this.$form.createForm(this),
      orgId: "",
      roleIds: [],
    };
  },
  created() {
    const token = Vue.ls.get(ACCESS_TOKEN);
    this.headers = { "X-Access-Token": token };
  },
  methods: {
    add(record = {}) {
      this.edit(record);
    },
    edit(record) {
      this.model = {};
      let that = this;
      that.form.resetFields();
      that.userId = record.id;
      this.loadOrgaData();
      // that.roleList = that.roleList;
      // that.visible = true;
      // let roleIds = [];
      // if (record.roleIds && record.roleIds.length) {
      //   roleIds = record.roleIds.map((item) => {
      //     return parseInt(item);
      //   });
      // }
      // if (record.orgaId) {
      //   const params = {};
      //   params.id = record.orgaId;
      //   queryOrganizationById(params).then((res) => {
      //     if (res && res.code == 200)
      //       if (res.data) {
      //         const roleIds = res.data.roleIds;
      //         this.roleList = [
      //           ...this.rolesList.filter((item) =>
      //             roleIds.map((info) => info == item.value).includes(true)
      //           ),
      //         ];
      //         // if (!this.personType) {
      //         //   this.roleList = [
      //         //     ...this.roleList.filter(
      //         //       (item) => !item.label.includes("管理员")
      //         //     ),
      //         //   ];
      //         // }
      //       }
      //   });
      // }
      // record.roleIds = [...roleIds];
      that.visible = true;
      that.model = { ...record };
      that.$nextTick(() => {
        that.form.setFieldsValue(
          pick(
            this.model,
            "loginName",
            "username",
            "roleIds",
            "orgaId",
            "position",
            "phonenum",
            "email",
            "userBlngOrgaDsplSeq",
            "description",
            "userCountLimit"
          )
        );
      });
    },
    close() {
      this.$emit("close");
      this.visible = false;
      this.disableSubmit = false;
      this.roleList = [];
      this.model = {};
    },
    handleBlurLoginName(e) {},
    handleBlurPhonenum(e) {},
    handleOk() {
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = Object.assign(this.model, values);
          formData.orgId = this.orgId;
          formData.orgaId = this.orgId;
          formData.roleIds = that.roleIds;
          let obj;
          if (!this.model.id) {
            formData.id = this.userId;
            // formData.personType = this.personType;
            obj = addUser(formData);
          } else {
            obj = editUser(formData);
          }
          obj
            .then((res) => {
              if (res.code === 200) {
                that.$emit("ok");
                this.roleList = [];
              } else {
                that.$message.warning(res.data.message);
                this.roleList = [];
              }
            })
            .finally(() => {
              that.confirmLoading = false;
              that.close();
            });
        }
      });
    },
    handleCancel() {
      this.close();
    },
    loadOrgaData() {
      let that = this;
      let params = {};
      selectCurrentOrgList(params).then((res) => {
        if (res) {
          that.orgaTree = res;
          that.orgId = res[0].id;
          that.loadRoleData();
        }
      });
      // params.id='';
      // queryOrganizationTreeList(params).then((res)=>{
      //   if(res){
      //     that.orgaTree = res
      //   }
      // })
    },
    loadRoleData() {
      let that = this;
      let params = {
        UBType: "UserRole",
        UBKeyId: "",
      };
      findUserRole(params).then((res) => {
        if (res) {
          that.rolesList = res.map((item) => {
            item.value = item.id;
            item.label = item.text;
            return item;
          });
          that.roleIds = res.map((item) => {
            return parseInt(item.id);
          });
          // that.roleList = [...that.rolesList]
        }
      });
    },
    changeRole(checkedValues) {
      console.log("checkedValues ---" + checkedValues);
    },
    onSelect(selectedKeys, e) {
      console.log("selected", selectedKeys, e);
      let params = {};
      let record = e.dataRef;
      params.id = record.id;
      queryOrganizationById(params).then((res) => {
        if (res && res.code == 200)
          if (res.data) {
            const roleIds = res.data.roleIds;
            // this.roleList = [...this.rolesList.filter(item=>roleIds.indexOf((item.value+"")))]
            this.roleList = [
              ...this.rolesList.filter((item) =>
                roleIds.map((info) => info == item.value).includes(true)
              ),
            ];
            record.roleIds = roleIds.map((item) => {
              return parseInt(item);
            });
            // if (this.personType) {
            //   //  this.rolesList.filter(item=>record.roleIds.map(info=> info==item.value).includes("管理员"))
            //   console.log(
            //     this.rolesList
            //       .filter((item) =>
            //         record.roleIds
            //           .map((info) => info == item.value)
            //           .includes(true)
            //       )
            //       .filter((items) => items.label.includes("管理员"))
            //       .map((p) => p.value)
            //   );
            //   record.roleIds = [
            //     ...this.rolesList
            //       .filter((item) =>
            //         record.roleIds
            //           .map((info) => info == item.value)
            //           .includes(true)
            //       )
            //       .filter((items) => items.label.includes("管理员")),
            //   ].map((p) => p.value);
            //   this.setValuesToForm(record);
            // } else {
            //   this.roleList = [
            //     ...this.roleList.filter(
            //       (item) => !item.label.includes("管理员")
            //     ),
            //   ];
            // }
          }
      });
    },
    // 触发onSelect事件时,为单位树右侧的form表单赋值
    setValuesToForm(record) {
      console.log(record);
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(record, "roleIds"));
        this.componentKey += 1;
      });
    },
  },
};
</script>

<style scoped></style>
